import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const IndexLink = ({ children, styles }) => (
  /* 
    Wobble effect:
    animation: `wobble ${
      Math.random() + 2
    }s cubic-bezier(${Math.random()},${Math.random()},${Math.random()},${Math.random()}) infinite`,
    animationDelay: `${Math.random() / 2}s`,
  */
  <div css={{ ...styles }}>{children}</div>
);

const IndexPage = () => (
  <Layout title="home">
    <section
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        font-weight: bolder;
        font-size: 2.5rem;
        margin-top: -20px;
        @media (max-width: 421px) {
          font-size: 2rem;
        }
      `}
    >
      <IndexLink
        styles={css`
          margin-left: -160px;
          /*color: #ff0000; makes "about me" red*/
          color: "White";
        `}
      >
        <Link to="/about">about me</Link>
      </IndexLink>
      <IndexLink
        styles={css`
          margin-right: -160px;
          /*color: #ff8a00;*/
          color: "White";
          margin-top: 60px;
        `}
      >
        <Link to="/stories">stories</Link>
      </IndexLink>
      <IndexLink
        styles={css`
          margin-left: -150px;
          /*color: #dbff00;*/
          color: "White";    
          margin-top: 60px;
        `}
      >
        <Link to="/words">words</Link>
      </IndexLink>
      <IndexLink
        styles={css`
          margin-right: -100px;
          /*color: #86f000;*/
          color: "White";
          margin-top: 60px;
        `}
      >
        <Link to="/videos">videos</Link>
      </IndexLink>
      <IndexLink
        styles={css`
          /*color: #00f0ff;*/
          color: "White";
          margin-top: 100px;
        `}
      >
        <Link to="/other">other stuff</Link>
      </IndexLink>
      <div
        css={css`
          margin-top: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
        `}
      >
        <IndexLink
          styles={{
            marginRight: "2vw",
            //color: "#87cefa",
            color: "White",
          }}
        >
          <a
            css={css`
              display: inline-block;
              transform: rotate(40deg);
            `}
            href="https://twitter.com/willkueshner"
          >
            bird words
          </a>
        </IndexLink>

        <IndexLink
          styles={{
            marginLeft: "2vw",
            color: "#87cefa",
            //color: "#eb00ff",
            color: "White",
          }}
        >
          <Link
            css={css`
              display: inline-block;
              transform: rotate(-40deg);
            `}
            to="/questions"
          >
            questions
          </Link>
        </IndexLink>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
